import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

import {Dialog, Button, TextField, Typography, Autocomplete, Grid, Container, Backdrop, CircularProgress, Alert, AlertTitle } from '@mui/material';

import FormAttachment from './FormAttachment';

const containerStyle = {
    backgroundColor: "white",
    marginTop: (theme) => theme.spacing(1),
    padding: {xs: '16px', sm: '32px'},
    borderRadius: {xs: '0', sm: '10%'}
  };

  const headerStyle = {
    fontSize: {xs: '16px', sm: '28px'}
  }

  const tenderHeaderStyle = {
    fontSize: {xs: '14px', sm: '18px'}
  }

export default function TenderForm({open, setOpen}) {
    const { handleSubmit, control, watch, setValue, reset } = useForm({ defaultValues: {
        nimi: "",
        phoneNum: "",
        email: "",
        kunta: "",
        palvelu: "",
        kiinteistotunnus: "",
        viesti: "",
        liite: null
    }});

    const kuntaValue = watch("kunta");
    const palveluValue = watch("palvelu");

    const [kuntaOptions, setKuntaOptions] = useState([]);
    const [palveluOptions, setPalveluOptions] = useState([]);

    const [yrityspalvelukunnat, setYrityspalvelukunnat] = useState([]);
    const [selectedYrityspalvelukunnat, setSelectedYrityspalvelukunnat] = useState([]);

    const [loading, setLoading] = useState(false);

    const [sendSuccess, setSendSuccess] = useState(false);
    const [sendFailure, setSendFailure] = useState(false);

    useEffect(() => {
        getYrityspalvelukunnat()
      }, []);
    
    const getYrityspalvelukunnat = async () => {
        try {
          const response = await axios.get("/api/data/yrityspalvelukunnat")

          setYrityspalvelukunnat(response.data)

          setKuntaOptions([...new Set(response.data.map((obj) => obj.kunta).sort())])
          setPalveluOptions([...new Set(response.data.map((obj) => obj.palvelu).sort())])

        } catch(error) {
          console.log(error)
        }
    }

    const setSelectedYrityspalvelukunnatHelper = (kunta, palvelu) => {

        if(kunta) {
            setSelectedYrityspalvelukunnat(yrityspalvelukunnat?.filter((obj) => obj.palvelu === palvelu).filter((obj) => obj.kunta === kunta))
        } else {
            setSelectedYrityspalvelukunnat(yrityspalvelukunnat?.filter((obj) => obj.palvelu === palvelu))
        }
    }

    const handleKuntaChange = (value) => {
        setValue("kunta", value);
        
        if (value === null) {
            setPalveluOptions([...new Set(yrityspalvelukunnat.map((obj) => obj.palvelu).sort())]);
        } else {
            if (!yrityspalvelukunnat.some((obj) => obj.kunta === value && obj.palvelu === palveluValue)) {
                setValue("palvelu", null);
            }
            setPalveluOptions([...new Set(yrityspalvelukunnat.filter((obj) => obj.kunta === value).map((obj) => obj.palvelu).sort())]);
        }
        setSelectedYrityspalvelukunnatHelper(value, palveluValue);
    };

    const handlePalveluChange = (value) => {
        setValue("palvelu", value);
        
        if (value === null) {
            setKuntaOptions([...new Set(yrityspalvelukunnat.map((obj) => obj.kunta).sort())]);
        } else {
            if (!yrityspalvelukunnat.some((obj) => obj.palvelu === value && obj.kunta === kuntaValue)) {
                setValue("kunta", null);
            }
            setKuntaOptions([...new Set(yrityspalvelukunnat.filter((obj) => obj.palvelu === value).map((obj) => obj.kunta).sort())]);
            setSelectedYrityspalvelukunnatHelper(kuntaValue, value);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = async (data) => {
        setLoading(true)

        const fData = new FormData()

        for (const key in data) {
            fData.append(key, data[key])
        }

        try {
          await axios.post("/api/yhteydenotto/kilpailutus", fData)
          setSendSuccess(true);
          
          reset();
          setValue("kunta", null);
          setValue("palvelu", null);
          setKuntaOptions([...new Set(yrityspalvelukunnat.map((obj) => obj.kunta))])
          setPalveluOptions([...new Set(yrityspalvelukunnat.map((obj) => obj.palvelu))])
          setSelectedYrityspalvelukunnat([])

          setLoading(false)
          setTimeout(() => {
            setSendSuccess(false)
            handleClose()
          }, 3000);
        } catch(error) {
          setSendFailure(true)
          setLoading(false)
          setTimeout(() => {
            setSendFailure(false)
          }, 3000);
        }
    }

  return (
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
      >
        <Container maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
            {(!sendSuccess && !sendFailure) &&
                <Grid container spacing={2} sx={containerStyle}>
                    <Grid item xs={12} >
                        <Typography variant="h5" align="center" sx={headerStyle}>
                            Pyydä tarjous haluamastasi palvelusta
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="nimi"
                            control={control}
                            rules={{
                                required: 'Syötä nimi',
                                maxLength: { value: 50, message: "Nimen oltava korkeintaan 50 merkkiä" },
                            }}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <TextField fullWidth label="Nimi*" name={name} variant="outlined" value={value} onChange={onChange} autoFocus error={!!error} helperText={error ? error.message : null} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="phoneNum"
                            control={control}
                            rules={{required: 'Syötä puhelinnumero', maxLength: { value: 15, message: "Puhelinnumeron oltava korkeintaan 15 merkkiä" } }}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <TextField fullWidth label="Puhelinnumero*" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                            maxLength: { value: 50, message: "Sähköpostin oltava korkeintaan 50 merkkiä" },
                            pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: "Tarkista sähköposti" }}}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <TextField fullWidth label="Sähköposti" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Controller
                            name="palvelu"
                            control={control}
                            rules={{required: 'Syötä palvelu'}}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <Autocomplete
                                    options={palveluOptions}
                                    clearOnEscape
                                    name={name}
                                    value={value}
                                    onChange={(_, value) => {handlePalveluChange(value)}}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Palvelu*" name={name} variant="outlined" error={!!error} helperText={error ? error.message : null} />
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Controller
                            name="kunta"
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <Autocomplete
                                    options={kuntaOptions}
                                    clearOnEscape
                                    name={name}
                                    value={value}
                                    onChange={(_, value) => {handleKuntaChange(value)}}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Kunta" name={name} variant="outlined" error={!!error} helperText={error ? error.message : null} />
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="kiinteistotunnus"
                            control={control}
                            rules={{
                                maxLength: { value: 30, message: "Tarkista kiinteistötunnus" }
                            }}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <TextField label="Kohteen kiinteistötunnus" variant="outlined" error={!!error} helperText={error ? error.message : null}
                                value={value} name={name} onChange={onChange} fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Controller
                            name="viesti"
                            control={control}
                            rules={{
                                maxLength: { value: 500, message: "Kentän oltava korkeintaan 500 merkkiä" },
                            }}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <TextField
                                multiline
                                rows={4}
                                fullWidth
                                variant="outlined"
                                label="Viesti"
                                value={value}
                                name={name}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : "Kuvaa tarvittaessa tilannettasi tarkemmin"}
                            />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormAttachment control={control} setValue={setValue}/>
                    </Grid>
                    {palveluValue &&
                        <Grid item xs={12} >
                            <Typography sx={tenderHeaderStyle}>Tarjouspyynnöt lähetetään yrityksille:</Typography>
                            <ul>
                                {[...new Set(selectedYrityspalvelukunnat.map((obj) => obj.nimi))].map((nimi) => { 
                                        return ( <li>{nimi}</li> )
                                })}
                            </ul>
                        </Grid>
                    }
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={6}>
                            <Button type="submit" fullWidth variant="contained" color="primary">
                            Lähetä
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={handleClose} fullWidth variant="contained" color="primary">
                            Peruuta
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
            </form>
            {sendSuccess &&
            <Alert severity="success">
                <AlertTitle>Tarjouspyynnöt lähetetty onnistuneesti</AlertTitle>
            </Alert>
            }
            {sendFailure &&
            <Alert severity="error">
                <AlertTitle>Tarjouspyyntöjen lähettäminen epäonnistui</AlertTitle>
                Yritä myöhemmin uudelleen tai ole yhteydessä järjestelmänvalvojaaan.
            </Alert>
            }
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
      </Dialog>
  );
}