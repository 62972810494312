import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {Button, TextField, Typography, Grid, Container, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, Backdrop, CircularProgress, Alert, AlertTitle, Autocomplete } from '@mui/material';

const containerStyle = {
  marginBottom: (theme) => theme.spacing(8),
  marginTop: (theme) => theme.spacing(2)
};

const inputLabelStyle = {
	paddingRight: '2px',
	backgroundColor: "white",
};

const buttonStyle = {
  margin: (theme) => theme.spacing(2, 2),
  width: "30%"
}

const buttonContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}

const pictureInfoContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

const pictureButtonStyle = {
  marginTop: (theme) => theme.spacing(1),
  width: '50%'
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function RegisterCompany({id, companyInfo}) {
  const navigate = useNavigate();

  const { handleSubmit, control, reset, getValues, setValue } = useForm({ defaultValues: companyInfo });

  const [maakuntaOptions, setMaakuntaOptions] = useState([]);
  const [kuntaOptions, setKuntaOptions] = useState([]);
  const [palveluOptions, setPalveluOptions] = useState([]);

  const [loading, setLoading] = useState(false);

  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationFailure, setRegistrationFailure] = useState(false);

  useEffect(() => {
    getMaakunnat();
    getKunnat();
    getPalvelut();
  }, [])

  const getMaakunnat = async () => {
    try {
      const response = await axios.get("/api/data/maakunnat")

      const maakunnat = response.data

      setMaakuntaOptions(maakunnat)
    } catch(error) {
      console.log(error)
    }
  }

  const getKunnat = async () => {
    try {
      const response = await axios.get("/api/data/kunnat")

      const kunnat = response.data

      setKuntaOptions(kunnat)
    } catch(error) {
      console.log(error)
    }
  }

  
  const getPalvelut = async () => {
    try {
      const response = await axios.get("/api/data/palvelut")

      const palvelut = response.data

      setPalveluOptions(palvelut)
    } catch(error) {
      console.log(error)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)

    const fData = new FormData()
    for (const key in data) {
      fData.append(key, data[key])
    }

    if (id === "uusi") {
      try {
        await axios.post("/api/admin/luo-yritys", fData)
        setLoading(false)
        setRegistrationSuccess(true)
        setTimeout(() => {
          setRegistrationSuccess(false)
          navigate('/admin/companies');
        }, 3000);
      } catch(error) {
        setLoading(false)
        setRegistrationFailure(true)
        setTimeout(() => {
          setRegistrationFailure(false)
        }, 5000);
      }
    } else {
      try {
        await axios.post("/api/admin/paivita-yritys", fData)
        setRegistrationSuccess(true);
        setLoading(false)
        setTimeout(() => {
          setRegistrationSuccess(false)
          navigate('/admin/companies');
        }, 3000);
      } catch(error) {
        console.log(error);
        setRegistrationFailure(true)
        setLoading(false)
        setTimeout(() => {
          setRegistrationFailure(false)
        }, 5000);
      }
    }
  }

  const revokeObjectUrl = () => {
    const logo = getValues("logo")

    if (logo instanceof File) {
      URL.revokeObjectURL(logo.kuva_url);
    }
  }

  const handlePictureChange = (event, onChange) => {

    // If user cancels file selection during handling, do nothing
    if (!event.target.files[0]) {
      return
    }

    revokeObjectUrl()

    const logoUrl = URL.createObjectURL(event.target.files[0])

    let file = event.target.files[0]
    file.kuva_url = logoUrl

    onChange(file);
  };

  
  const handleRemovingPicture = () => {

    revokeObjectUrl()
    setValue("logo", null)
  };

  const handleMultipleSelectChange = (event, onChange) => {
    const { target: { value }, } = event;

    onChange(typeof value === 'string' ? value.split(',') : value,);
  };

  const cancelChanges = () => {
    reset();
  }

  return (
    <Container maxWidth="sm" sx={containerStyle}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!registrationSuccess &&
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        name="logo"
                        control={control}
                        render={({ field: { onChange, value, name} }) => (
                          <Grid item container xs={12} >
                            <Grid item xs={12}>
                              <input accept="image/*" id="picture-file-input" type="file" style={{ display: 'none' }} onChange={(event) => handlePictureChange(event, onChange)}/>
                              <label htmlFor="picture-file-input">
                              <img src={value?.kuva_url? value.kuva_url : "../../image_placeholder.jpeg"} alt="" style={{objectFit: 'contain', width: '100%', height: 260 }}/>
                              </label>
                            </Grid>
                            <Grid item xs={12}>
                              {value ?
                              <div style={pictureInfoContainerStyle}> 
                                <Button sx={pictureButtonStyle} type="button" variant="contained" color="primary" onClick={() => handleRemovingPicture(name)}>Poista logo</Button>
                              </div>
                                :
                              <div style={pictureInfoContainerStyle}>
                                <Typography>
                                  Valitse logo klikkaamalla kuvaketta
                                </Typography>
                              </div>
                              }
                            </Grid>
                          </ Grid >
                        )}
                    />
                </Grid>
                <Grid item xs={12} >
                <Controller
                  name="ytunnus"
                  control={control}
                  rules={{
                    required: 'Syötä ytunnus',
                    pattern: { value: /^[0-9]{7}-[0-9]{1}$/, message: "Tarkista y-tunnus" }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField fullWidth label="Y-tunnus" name="ytunnus" variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                  )}
                />
              </Grid>
              <Grid item xs={12} >
                <Controller
                  name="nimi"
                  control={control}
                  rules={{
                    required: 'Syötä yrityksen nimi',
                    maxLength: { value: 50, message: "Nimen on oltava korkeintaan 50 merkkiä" },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField fullWidth label="Yrityksen nimi" name="nimi" variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                  )}
                />
              </Grid>
              <Grid item xs={12} >
                <Controller
                  name="palvelunimi"
                  control={control}
                  rules={{
                    required: 'Syötä palvelun nimi',
                    maxLength: { value: 50, message: "Nimen on oltava korkeintaan 50 merkkiä" },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField fullWidth label="Palvelun nimi" name="palvelunimi" variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                  )}
                />
              </Grid>
              <Grid item xs={12} >
                <Controller
                  name="linkki"
                  control={control}
                  rules={{
                    maxLength: { value: 200, message: "Linkin oltava korkeintaan 200 merkkiä pitkä" }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField fullWidth label="Linkki" name="linkki" variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                  )}
                />
              </Grid>
              <Grid item xs={12} >
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'Syötä sähköposti',
                    maxLength: { value: 50, message: "Sähköpostin oltava korkeintaan 50 merkkiä" },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField fullWidth label="Sähköposti" name="email" variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                    name="phoneNum"
                    control={control}
                    rules={{ maxLength: { value: 15, message: "Puhelinnumeron oltava korkeintaan 15 merkkiä" } }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <TextField fullWidth label="Puhelinnumero" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                    )}
                />
              </Grid>
              <Grid item xs={12} >
                <Controller
                  name="osoite"
                  control={control}
                  rules={{
                    maxLength: { value: 200, message: "Osoitteen oltava korkeintaan 200 merkkiä" },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField fullWidth label="Osoite" name="osoite" variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                    name="maakunnat"
                    control={control}
                    rules={{ required: 'Syötä maakunnat', }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <FormControl sx={{width:'100%'}}>
                        <InputLabel id="demo-multiple-checkbox-label" sx={inputLabelStyle}>Maakunnat</InputLabel>
                        <Select
                          id={name}
                          labelId="demo-multiple-checkbox-label"
                          fullWidth
                          multiple
                          error={!!error}
                          value={value}
                          onChange={(event) => handleMultipleSelectChange(event, onChange)}
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                        >
                          {maakuntaOptions.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={value.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                    name="kunnat"
                    control={control}
                    rules={{ required: 'Syötä kunnat', }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <FormControl sx={{width:'100%'}}>
                        <InputLabel id="demo-multiple-checkbox-label" sx={inputLabelStyle}>Kunnat</InputLabel>
                        <Select
                          id={name}
                          labelId="demo-multiple-checkbox-label"
                          fullWidth
                          multiple
                          error={!!error}
                          value={value}
                          onChange={(event) => handleMultipleSelectChange(event, onChange)}
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                        >
                          {kuntaOptions.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={value.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="palvelu"
                  control={control}
                  rules={{ required: 'Syötä palvelu'}}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      name={"palvelu"}
                      select
                      fullWidth
                      label="Palvelu"
                      error={!!error}
                      value={value}
                      onChange={onChange}
                    >
                      {palveluOptions.map((palvelunimi) => (
                        <MenuItem key={palvelunimi} value={palvelunimi}>{palvelunimi}</MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} >
                <Controller
                  name="prioriteetti"
                  control={control}
                  rules={{
                    validate: (value) => {
                      return Number.isInteger(Number(value)) && Number(value) >= 0 || "Numeron oltava positiivinen kokonaisluku";
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField fullWidth label="Prioriteetti" name="prioriteetti" variant="outlined" type="number" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                  )}
                />
              </Grid>
              <Grid item xs={12} >
                <Controller
                  name="sisallyta_palvelulistaan"
                  control={control}
                  rules={{
                    validate: (value) => {
                      return value === "0" || value === "1" || "Numeron oltava 0 tai 1 (1=Kyllä, 0=Ei)";
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField fullWidth label="Sisällytä palvelulistaan (1=Kyllä, 0=Ei)" name="sisallyta_palvelulistaan" variant="outlined" type="number" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="kuvaus"
                  control={control}
                  rules={{
                    maxLength: { value: 2000, message: "Kentän oltava korkeintaan 2000 merkkiä" },
                  }}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      multiline
                      rows={5}
                      variant="standard"
                      label="Kuvaus"
                      value={value}
                      name={name}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : "Tähän tulee palvelun/yrityksen kuvaus, joka näkyy ilmoituksessa"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={buttonContainerStyle}>
                <Button sx={buttonStyle} type="submit" variant="contained" color="primary" >
                  {id === "uusi" ? "Luo uusi" : "Tallenna"}
                </Button>
                <Button sx={buttonStyle} variant="contained" color="secondary" onClick={() => {cancelChanges()}}  >
                  PERUUTA
                </Button>
              </Grid>
            <Grid/>
            </Grid>
          }
        </form>
        {registrationSuccess &&
          <Alert severity="success">
            <AlertTitle>Tallentaminen onnistui</AlertTitle>
            Odota hetki, sinut siirretään takaisin admin-näkymään.
          </Alert>
        }
        {registrationFailure &&
          <Alert severity="error">
            <AlertTitle>Tallentaminen epäonnistui</AlertTitle>
            Kokeile myöhemmin uudelleen tai ole yhteydessä järjestelmänvalvojaan.
          </Alert>
        }
        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
    </Container>
  );
}