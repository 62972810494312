import React, { useState, useEffect } from 'react';

import { Controller } from "react-hook-form";

import {Container, Typography, IconButton, Button, Alert, AlertTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const fileNameContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: (theme) => theme.spacing(2)
  }

const buttonStyle = {
  width: (theme) => theme.spacing(24)
}

const alertStyle = {
    marginTop: (theme) => theme.spacing(2)
}

export default function FormAttachment({control, setValue}) {

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {

    // When component is unmounted, set the value of the attachment to null
    return () => {
        setValue("liite", null);
    }
  }, []);

  const handleFileUpload = (e) => {
  
    if (!e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];

    if (file.size > 31457280) { // This is 30MB which should be the limit for the size.
        setErrorText("Tiedostojen koko saa olla korkeintaan 30MB")
        setShowError(true);
        setTimeout(() => {
            setErrorText("")
            setShowError(false);
        }, 5000);
        
        return;
    }

    setValue("liite", file);
  }


  return (
    <Container sx={containerStyle}>
        <input id="attachment-file-input" type="file" name="liite" style={{ display: 'none' }} onChange={handleFileUpload}/>
        <label htmlFor="attachment-file-input">
            <Button sx={buttonStyle} component="span" variant="contained" >
            Lisää liite
            </Button>
        </label>
        {showError &&
            <Alert severity="error" sx={alertStyle}>
            <AlertTitle>Liitteen lisääminen epäonnistui</AlertTitle>
            {errorText}
            </Alert>
        }
        <Controller
            name="liite"
            control={control}
            render={({ field: { value } }) => (
                <Container sx={fileNameContainerStyle}>
                    {value &&
                        <Typography> {value.name}
                        <IconButton
                            onClick={() => {setValue("liite", null);}}
                            size="large">
                            <CloseIcon/>
                        </IconButton>
                        </Typography>
                    } 
                </Container>
            )}
        />
    </Container>
  );
}