import React, { useRef, useState, useEffect } from 'react';
import { Controller } from "react-hook-form";
import { NumericFormat } from 'react-number-format';
import axios from "axios";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import BasicDateTimePicker from './DateTimePicker';
import BasicDatePicker from './DatePicker';

import { TextField, Grid, MenuItem, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Container, Autocomplete, Tooltip } from '@mui/material';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: "center",
}

const innerContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

}

const fieldStyle = {
  width: (theme) => theme.spacing(28),
  marginTop: '8px'
};


function InfoIconTextField(props) {
  return (
    <>
      <TextField {...props} InputProps={{
        endAdornment: (
          <Tooltip title="Muun maan pinta-alaan kuuluvat kitumaat, joutomaat, vesialueet, pellot ja muut alueet, jotka eivät liity metsätalouteen." arrow placement="top-start">
            <InfoOutlinedIcon fontSize="medium"/>
          </Tooltip>
        ),
      }}/>
    </>
  )
}

function formatToMaxTwoDecimalPoints(numberString) {
  // Check if the input is an empty string
  if (numberString === '') {
      return numberString;
  }

  const number = Number(numberString);

  const decimalCount = (number.toString().split('.')[1] || '').length;
  return number.toFixed(Math.min(2, decimalCount));
}

export default function Perustiedot({control, watch, setValue, getValues, setOpenYhteismetsatiedot}) {

  const [kuntaOptions, setKuntaOptions] = useState([]);
  const [maakuntaOptions, setMaakuntaOptions] = useState([]);

  
  const ilmoitustyyppiSelect = useRef({});
  ilmoitustyyppiSelect.current = watch("tyyppi");

  const tarjouskauppaSelect = useRef({});
  tarjouskauppaSelect.current = watch("tarjouskauppa");

  const metsaArvioSelect = useRef({});
  metsaArvioSelect.current = watch("metsa_arvio");

  const metsamaaSelect = useRef({});
  metsamaaSelect.current = watch("metsamaa_koko");

  const muumaaSelect = useRef({});
  muumaaSelect.current = watch("muumaa_koko");

  const getKunnat = async () => {
    try {
      const response = await axios.get("/api/data/kunnat")
      setKuntaOptions(response.data)
    } catch(error) {
      console.log(error)
    }
  }

  const getMaakunnat = async () => {
    try {
      const response = await axios.get("/api/data/maakunnat")
      setMaakuntaOptions(response.data)
    } catch(error) {
      console.log(error)
    }
  }

  const changeIlmoitustyyppi = (tyyppi) => {
    if(tyyppi === "Yhteismetsäosuus") {
      setOpenYhteismetsatiedot(true)
    } else {
      setOpenYhteismetsatiedot(false)
    }
  }

  useEffect(() => {
    getKunnat()
    getMaakunnat()
  }, []);

  return (
    <Container sx={containerStyle}>
      <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <Controller
              name="tyyppi"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <TextField
                  name={name}
                  value={value}
                  onChange={(event) => {
                    changeIlmoitustyyppi(event.target.value)
                    onChange(event)
                  }}
                  select // tell TextField to render select
                  variant="standard"
                  label="Ilmoitustyyppi"
                  sx={fieldStyle}
                >
                  <MenuItem key={1} value="Metsätila (kiinteistö)">
                    Metsätila (kiinteistö)
                  </MenuItem>
                  <MenuItem key={2} value="Metsätila (määräala)">
                    Metsätila (määräala)
                  </MenuItem>
                  <MenuItem key={3} value="Yhteismetsäosuus">
                    Yhteismetsäosuus
                  </MenuItem>
                  <MenuItem key={4} value="Tontti">
                    Tontti
                  </MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <Controller
              name="maakunta"
              control={control}
              rules={{
                validate: value => !(ilmoitustyyppiSelect.current !== 'Yhteismetsäosuus' && !value) || "Syötä maakunta"
              }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <Autocomplete
                  options={maakuntaOptions}
                  clearOnEscape
                  name={name}
                  value={value}
                  onChange={(_, value) => {onChange(value)}}
                  renderInput={(params) =>
                    <TextField {...params} label={ilmoitustyyppiSelect.current !== 'Yhteismetsäosuus' ? "Maakunta*" : 'Maakunta'} variant="standard" margin="normal" error={!!error} helperText={error ? error.message : null} sx={fieldStyle}/> 
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <Controller
              name="kunta"
              control={control}
              rules={{
                validate: value => !(ilmoitustyyppiSelect.current !== 'Yhteismetsäosuus' && !value) || "Syötä kunta"
              }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <Autocomplete
                  options={kuntaOptions}
                  clearOnEscape
                  name={name}
                  value={value}
                  onChange={(_, value) => {onChange(value)}}
                  renderInput={(params) =>
                    <TextField {...params} label={ilmoitustyyppiSelect.current !== 'Yhteismetsäosuus' ? "Kunta*" : 'Kunta'} variant="standard" margin="normal" error={!!error} helperText={error ? error.message : null} sx={fieldStyle}/>
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <Controller
              name="metsamaa_koko"
              control={control}
              rules={{
                validate: value => !(ilmoitustyyppiSelect.current !== 'Yhteismetsäosuus' && isNaN(parseFloat(value))) || "Syötä metsämaan pinta-ala"
              }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <NumericFormat
                  sx={fieldStyle}
                  isNumericString={true}
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  thousandSeparator=" "
                  decimalSeparator="."
                  isAllowed={(values) => {return values.value < 1000000}}
                  label={ilmoitustyyppiSelect.current !== 'Yhteismetsäosuus' ? "Metsämaan pinta-ala (ha)*" : 'Metsämaan pinta-ala (ha)'}
                  variant="standard"
                  value={value}
                  name={name}
                  error={!!error}
                  helperText={error ? error.message : null}
                  onValueChange={values => {
                    const metsamaa = values.value ? parseFloat(values.value) : ""
                    const muumaa = muumaaSelect.current ? parseFloat(muumaaSelect.current) : ""
                    setValue("koko", formatToMaxTwoDecimalPoints(metsamaa + muumaa));
                    onChange({
                      target: {
                        value: metsamaa
                      }
                    });
                  }}
                  customInput={TextField}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{display: 'flex', flexDirection:'row', justifyContent: 'center', alignItems: 'center'}}>
            <Controller
              name="muumaa_koko"
              control={control}
              rules={{
                validate: value => !(ilmoitustyyppiSelect.current !== 'Yhteismetsäosuus' && isNaN(parseFloat(value))) || "Syötä muun maan pinta-ala"
              }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <NumericFormat
                  sx={fieldStyle}
                  isNumericString={true}
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  thousandSeparator=" "
                  decimalSeparator="."
                  isAllowed={(values) => {return values.value < 1000000}}
                  label={ilmoitustyyppiSelect.current !== 'Yhteismetsäosuus' ? "Muun maan pinta-ala (ha)*" : 'Muun maan pinta-ala (ha)'}
                  variant="standard"
                  value={value}
                  name={name}
                  error={!!error}
                  helperText={error ? error.message : null}
                  onValueChange={values => {
                    const muumaa = values.value ? parseFloat(values.value) : ""
                    const metsamaa = metsamaaSelect.current ? parseFloat(metsamaaSelect.current) : ""
                    setValue("koko", formatToMaxTwoDecimalPoints(muumaa + metsamaa));
                    onChange({
                      target: {
                        value: muumaa
                      }
                    });
                  }}
                  customInput={InfoIconTextField}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
          <Controller
            name="koko"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <TextField
                sx={fieldStyle}
                disabled={true}
                name={name}
                value={value}
                variant="standard"
                label={ilmoitustyyppiSelect.current !== 'Yhteismetsäosuus' ? "Tilan pinta-ala (ha)*" : 'Tilan pinta-ala (ha)'}
                onChange={onChange}
              />
            )}
          />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <Controller
              name="hintapyynto"
              control={control}
              rules={{
                required: 'Syötä hintapyyntö',
              }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <NumericFormat
                  sx={fieldStyle}
                  isNumericString={true}
                  decimalScale={0}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  thousandSeparator=" "
                  label="Hintapyyntö (€)*"
                  variant="standard"
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  name={name}
                  onValueChange={values => {
                    onChange({
                      target: {
                        value: values.value
                      }
                    });
                  }}
                  customInput={TextField}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <FormControl sx={fieldStyle}>
              <FormLabel>Tarjouskauppa</FormLabel>
              <Controller
                name="tarjouskauppa"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <RadioGroup row value={String(value)} name={name} onChange={onChange} >
                    <FormControlLabel value="true" control={<Radio />} label="Kyllä" />
                    <FormControlLabel value="false" control={<Radio />} label="Ei" />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <FormControl>
              <FormLabel>Tarjouskauppa Päättyy</FormLabel>
              <Controller
                name="tarjouskauppa_paattyy"
                control={control}
                rules={{
                  validate: {
                    checkTarjouskauppa: (value) => {
                      // Checking that if the tarjouskauppa is true then this value shall not be null
                      return !(getValues("tarjouskauppa") === 'true' && !value)}
                  }
                }}
                render={({ field: { onChange, value, name } }) => (
                  <BasicDateTimePicker onChange={onChange} value={value} name={name} pickDisabled={tarjouskauppaSelect.current === "false"} />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <Controller
              name="kiinteistotunnus"
              control={control}
              rules={{
                required: 'Syötä kiinteistötunnus',
                maxLength: { value: 30, message: "Tarkista kiinteistötunnus" }
              }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <TextField label="Kiinteistötunnus*" variant="standard" error={!!error} helperText={error ? error.message : null}
                  value={value} name={name} onChange={onChange} sx={fieldStyle}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <Controller
              name="kiinteistonimi"
              control={control}
              rules={{
                required: 'Syötä kiinteistön nimi',
                maxLength: { value: 100, message: "Kentän oltava korkeintaan 100 merkkiä" },
              }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <TextField label="Kiinteistön nimi*" variant="standard" error={!!error} helperText={error ? error.message : null}
                  value={value} name={name} onChange={onChange} sx={fieldStyle}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <Controller
              name="puu_maara"
              control={control}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <NumericFormat
                  sx={fieldStyle}
                  isNumericString={true}
                  decimalScale={0}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  thousandSeparator=" "
                  isAllowed={(values) => {return values.value < 10000000}} // Puun määrän tulisi olla alle kymmenen miljoonaa kuutiota
                  label="Puun määrä (m³)"
                  variant="standard"
                  value={value}
                  name={name}
                  onValueChange={values => {
                    onChange({
                      target: {
                        value: values.value
                      }
                    });
                  }}
                  customInput={TextField}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <Controller
              name="metsa_arvio"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <TextField
                  name={name}
                  value={value}
                  onChange={onChange}
                  select // tell TextField to render select
                  variant="standard"
                  label="Metsäarvio"
                  sx={fieldStyle}
                >
                  <MenuItem key={1} value="Ei ole">
                    Ei ole
                  </MenuItem>
                  <MenuItem key={2} value="Avoin metsävaratieto">
                    Avoin metsävaratieto
                  </MenuItem>
                  <MenuItem key={3} value="Maastotarkistettu">
                    Maastotarkistettu
                  </MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <FormControl>
              <FormLabel>Metsäarvion ajankohta</FormLabel>
              <Controller
                name="metsa_arvio_ajankohta"
                control={control}
                rules={{
                  validate: {
                    checkMetsaArvio: (value) => {
                      // Checking that if the metsa_arvio has been made. If so, then this value shall not be null
                      return !(getValues("metsa_arvio") !== 'Ei ole' && !value)}
                  }
                }}
                render={({ field: { onChange, value, name } }) => (
                  <BasicDatePicker onChange={onChange} value={value} name={name} pickDisabled={metsaArvioSelect.current === "Ei ole"} />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={innerContainerStyle}>
            <Controller
              name="yritysnimi"
              control={control}
              rules={{
                maxLength: { value: 50, message: "Kentän oltava korkeintaan 50 merkkiä" },
              }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  sx={fieldStyle}
                  label="Yritys"
                  value={value}
                  name={name}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : "Mikäli myyjänä on yritys, lisää tähän yrityksen nimi"}
                />
              )}
            />
          </Grid>
      </Grid>
    </Container>
  );
}