import React, { useState, useEffect } from 'react';
import axios from "axios";

import { useMediaQuery, Container, InputLabel, FormControl, NativeSelect, TextField, Typography, Box, Button, Link, Grid, Pagination, CircularProgress} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { useHistoryState } from '../../useHistoryState';
import MultiSelect from './MultiSelect';
import ItemList from './ItemsList';

const containerStyle = {
  marginBottom: (theme) => theme.spacing(10),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const headerContainerStyle = {
  height: {xs: '240px', sm: '360px'},
  position: 'relative',
  width: '100%',
  marginBottom: {xs: '10px', sm: '30px'},
  padding: 0
};

const headerAdContainerStyle = {
  marginBottom: {xs: '10px', sm: '20px'}
};

const headerAdStyle = {
  width: '100%',
  objectFit: 'contain'
}

const headerImageStyle = {
  width: '100vw',
  height: '100%',
  objectFit: 'cover',
}

const searchTextStyle = {
  fontWeight: 'bold',
  marginBottom: {xs: '20px', sm: '40px'},
}

const headerTextStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  color: '#ffffff',
  fontSize: {xs: '32px', sm: '72px'},
  lineHeight: '200%'
};

const headerSecondTextStyle = {
  fontSize: {xs: '18px', sm: '32px'},
};

const searchContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: (theme) => theme.spacing(6),
  borderStyle: 'solid',
  borderWidth: '5px',
  borderColor: (theme) => theme.palette.primary.light,
  borderRadius: '25px',
  marginBottom: {xs: '20px', sm: '30px'},
}

const searchBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const searchBoxHiddenStyle = {
  flexDirection: 'column',
  alignItems: 'center',
  display: {xs: 'none', lg: 'block'}
}

const buttonStyle = {
  margin: (theme) => theme.spacing(4, 2),
  fontSize: {xs: '12px', sm: '14px'},
  width: {xs: '60%', sm: "40%", md: "30%"},
}

const resetButtonStyle = {
  width:'195px',
  backgroundColor: 'transparent',
  "&:hover": {
    backgroundColor: (theme) => theme.palette.primary.light,
    color: 'white'
  }
}

const circularProgressStyle = {
  margin: (theme) => theme.spacing(4)
}

const paginationStyle = {
  margin: (theme) => theme.spacing(2,0)
}

export default function ItemSearch() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [kuntaOptions, setKuntaOptions] = useState([]);
  const [maakuntaOptions, setMaakuntaOptions] = useState([]);
  const [loading, setLoading] = useState(true)

  const [ilmoitusmaara, setIlmoitusmaara] = useHistoryState("ilmoitusmaara", 0)
  const [ilmoitusmaaraHaetut, setIlmoitusmaaraHaetut] = useHistoryState("ilmoitusmaaraHaetut", 0)
  const [ilmoitukset, setIlmoitukset] = useHistoryState("ilmoitukset", [])
  const [page, setPage] = useHistoryState("page", 1)

  const [tyypit, setTyypit] = useHistoryState("tyyppit", [])
  const [maakunnat, setMaakunnat] = useHistoryState("maakunnat", [])
  const [kunnat, setKunnat] = useHistoryState("kunnat", [])
  const [hintaMin, setHintaMin] = useHistoryState("hintaMin", "")
  const [hintaMax, setHintaMax] = useHistoryState("hintaMax", "")
  const [kokoMin, setKokoMin] = useHistoryState("kokoMin", "")
  const [kokoMax, setKokoMax] = useHistoryState("kokoMax", "")
  const [puumaaraMin, setPuumaaraMin] = useHistoryState("puumaaraMin", "")
  const [puumaaraMax, setPuumaaraMax] = useHistoryState("puumaaraMax", "")
  const [jarjestys, setJarjestys] = useHistoryState("jarjestys", "created_at DESC")

  useEffect(() => {
    getIlmoitusmaara()
  },[tyypit, maakunnat, kunnat, hintaMin, hintaMax, kokoMin, kokoMax, puumaaraMin, puumaaraMax]);

  useEffect(() => {
    window.scrollTo(0, 0);

    getKunnat();
    getMaakunnat();
  }, [])

  useEffect(() => {
    getIlmoituslista();
  },[page]);

  const getKunnat = async () => {
    try {
      const response = await axios.get("/api/data/kunnat")
      setKuntaOptions(response.data)
    } catch(error) {
      console.log(error)
    }
  }

  const getMaakunnat = async () => {
    try {
      const response = await axios.get("/api/data/maakunnat")
      setMaakuntaOptions(response.data)
    } catch(error) {
      console.log(error)
    }
  }

  const getIlmoitusmaara = async () => {
    const filters = {
      tyypit,
      maakunnat,
      kunnat,
      hintaMin,
      hintaMax,
      kokoMin,
      kokoMax,
      puumaaraMin,
      puumaaraMax
    }

    try {
      const response = await axios.post("/api/ilmoitus/hae-maara", filters)
      setIlmoitusmaara(response.data.ilmoitusmaara)
      return response.data.ilmoitusmaara
    } catch(error) {
      console.log(error);
    }
  }

  const getIlmoituslista = async () => {
    setLoading(true)
    const filters = {
      tyypit,
      maakunnat,
      kunnat,
      hintaMin,
      hintaMax,
      kokoMin,
      kokoMax,
      puumaaraMin,
      puumaaraMax,
      jarjestys,
      bottom_limit: (page - 1) * 10
    }

    try {
      const response = await axios.post("/api/ilmoitus/hae-ilmoituslista", filters)

      response.data.forEach(element => {
        if (element.tarjouskauppa_paattyy) {
          const date = new Date(element.tarjouskauppa_paattyy);
          element.tarjouskauppa_paattyy = date.toLocaleString('fi-FI', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
        }
      });

      setIlmoitukset(response.data)
      const ilmoitukset_lkm = await getIlmoitusmaara()
      setIlmoitusmaaraHaetut(ilmoitukset_lkm)
      setLoading(false)
    } catch(error) {
      console.log(error);
      setLoading(false)
    }
  }

  const resetSelections = () => {
    setTyypit([])
    setMaakunnat([])
    setKunnat([])
    setHintaMin("")
    setHintaMax("")
    setKokoMin("")
    setKokoMax("")
    setPuumaaraMin("")
    setPuumaaraMax("")
    setJarjestys("hintapyynto ASC")
  }

  const handlePageChange = (event, value) => {
    setPage(value)
  };

  const handleFetchIlmoituksetClick = () => {
    setPage(1)
    getIlmoituslista()
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Box component="div" sx={headerContainerStyle}>
        <img src={'../header-image-nettimetsa.png'} alt={""} style={headerImageStyle} />
        <Typography variant='h1' sx={headerTextStyle}><b>Nettimetsä.fi</b><br/><Typography sx={headerSecondTextStyle}><b>Julkinen metsätilojen ja -palveluiden markkinapaikka</b></Typography></Typography>
      </Box>
      <Grid container>
      <Grid item xs={0.5} lg={1.6}></Grid>
        <Grid item xs={11} lg={8.8} sx={containerStyle}>
          <Container sx={searchContainerStyle}>
            <Typography variant="h4" component="h1" sx={searchTextStyle}>Hae kohteita</Typography>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12} sm={6} md={4} lg={4} sx={searchBoxStyle}>
                <MultiSelect name="Kiinteistötyyppi" items={["Metsätila (kiinteistö)", "Metsätila (määräala)", "Yhteismetsäosuus", "Tontti"]} selectedItems={tyypit} setSelectedItems={setTyypit} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} sx={searchBoxStyle}>
                <MultiSelect name="Maakunta" items={maakuntaOptions} selectedItems={maakunnat} setSelectedItems={setMaakunnat} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} sx={searchBoxStyle}>
                <MultiSelect name="Kunta" items={kuntaOptions} selectedItems={kunnat} setSelectedItems={setKunnat} />
              </Grid>
              <Grid item container spacing={2} xs={12} sm={6} md={4} lg={3}>
                <Grid item xs={12} sx={searchBoxStyle}>
                  <TextField variant="standard" label="Hinta min" type="number" value={hintaMin} onChange={(event) => {setHintaMin(event.target.value)}} />
                </Grid>
                <Grid item xs={12} sx={searchBoxStyle}>
                  <TextField variant="standard" label="Hinta max" type="number" value={hintaMax} onChange={(event) => {setHintaMax(event.target.value)}} />
                </Grid>
              </Grid>
              <Grid item container spacing={2} xs={12} sm={6} md={4} lg={3}>
                <Grid item xs={12} sx={searchBoxStyle}>
                  <TextField variant="standard" label="Pinta-ala min (ha)" type="number" value={kokoMin} onChange={(event) => {setKokoMin(event.target.value)}} />
                </Grid>
                <Grid item xs={12} sx={searchBoxStyle}>
                  <TextField variant="standard" label="Pinta-ala max (ha)" type="number" value={kokoMax} onChange={(event) => {setKokoMax(event.target.value)}} />
                </Grid>
              </Grid>
              <Grid item container spacing={2} xs={12} sm={6} md={4} lg={3}>
                <Grid item xs={12} sx={searchBoxStyle}>
                  <TextField variant="standard" label="Puun määrä min (m3)" type="number" value={puumaaraMin} onChange={(event) => {setPuumaaraMin(event.target.value)}} />
                </Grid>
                <Grid item xs={12} sx={searchBoxStyle}>
                  <TextField variant="standard" label="Puun määrä max (m3)" type="number" value={puumaaraMax} onChange={(event) => {setPuumaaraMax(event.target.value)}} />
                </Grid>
              </Grid>
              <Grid item container spacing={2} xs={12} sm={6} md={4} lg={3}>
                <Grid item xs={12} sx={searchBoxHiddenStyle} >
                  <FormControl style={{backgroundColor: "transparent"}}>
                    <InputLabel shrink={true} >Järjestä</InputLabel>
                    <NativeSelect value={jarjestys} onChange={(e) => {setJarjestys(e.target.value)}} sx={{width:'195px'}}>
                      <option value={"created_at DESC"}>Uusin ensin</option>
                      <option value={"created_at ASC"}>Vanhin ensin</option>
                      <option value={"hintapyynto ASC"}>Halvin ensin</option>
                      <option value={"hintapyynto DESC"}>Kallein ensin</option>
                      <option value={"koko DESC"}>Suurin ensin</option>
                      <option value={"koko ASC"}>Pienin ensin</option>
                      <option value={"puu_maara DESC"}>Suurin puun määrä</option>
                      <option value={"puu_maara ASC"}>Pienin puun määrä</option>
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={searchBoxHiddenStyle}>
                  <Button variant="outlined" sx={resetButtonStyle} onClick={() => {resetSelections()}}>
                    Tyhjennä valinnat
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Button variant="contained" sx={buttonStyle} onClick={handleFetchIlmoituksetClick} >
              Hae {ilmoitusmaara} Kohdetta
            </Button>
          </Container>
          <Box component="div" sx={headerAdContainerStyle}>
            <a style={headerAdStyle} href={"https://aarimetsa.fi/"} target="_blank" rel="noreferrer"><img src={'../aari-mainos.png'} alt={""} style={headerAdStyle}/></a>
          </Box>
          {loading ?
            <CircularProgress color="primary" sx={circularProgressStyle}/>
            :
              <ItemList ilmoitukset={ilmoitukset}/>
          }
          <Pagination size={isMobile ? "small" : "large"} count={Math.ceil(ilmoitusmaaraHaetut / 10)} page={page} onChange={handlePageChange} sx={paginationStyle}/>
        </Grid>
        <Grid item xs={0.5} lg={1.6}></Grid>
      </Grid>
    </Container>
  );
}