import React from 'react';
import { Box, Typography } from '@mui/material';

import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';



const CustomTooltip = ({ payload }) => {
  if (payload && payload.length) {
      // Extract the data you need from the payload
      const data = payload[0].payload;
      // Customize the tooltip content
      return <div style={{backgroundColor: "white", padding: "4px"}}><Typography>{data.name}: {data.value} ha</Typography></div>;
  }

  return null;
};

export default function PuustotiedotPieChart({dataset}) {

  return (
    <ResponsiveContainer height={400} width="100%">
      <PieChart>
        <Pie
          dataKey="value"
          minAngle={8}
          isAnimationActive={false}
          data={dataset}
          cx="50%"
          cy="50%"
          outerRadius="35%"
          label={({value, label, cx, cy, midAngle, innerRadius, outerRadius}) => {
            const RADIAN = Math.PI / 180;
            // eslint-disable-next-line
            const radius = 25 + innerRadius + (outerRadius - innerRadius);
            // eslint-disable-next-line
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            // eslint-disable-next-line
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            return (
                <text
                    x={x}
                    y={y}
                    fill="#317d3f"
                    textAnchor={x > cx ? "start" : "end"}
                    dominantBaseline="central"
                    fontFamily='Garet'
                >
                    {label}: {value} ha
                </text>
            )
          }}
        >
            {dataset.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} style={{outline: 'none'}} />
            ))}
        </Pie>
        <Tooltip content={<CustomTooltip />}/>
      </PieChart>
    </ResponsiveContainer>
  );
}