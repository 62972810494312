import React from 'react';

import { Container, Typography, Box } from '@mui/material';

import ContactSubmit from './ContactSubmit';

const containerStyle = {
  marginBottom: (theme) => theme.spacing(12),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const headerContainerStyle = {
  height: {xs: '240px', sm: '360px'},
  position: 'relative',
  marginBottom: (theme) => theme.spacing(4),
};

const headerImageStyle = {
  width: '100vw',
  height: '100%',
  objectFit: 'cover',
}

const headerTextStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  color: '#ffffff',
  fontSize: {xs: '32px', sm: '72px'},
  lineHeight: '200%'
};

const smallHeaderStyle = {
  marginTop: (theme) => theme.spacing(4),
  marginBottom: (theme) => theme.spacing(2),
  alignItems: 'center',
  textAlign: 'center',
  overflowWrap: 'anywhere',
  fontSize: {xs: '24px', sm: '28px'},
};

const headerSecondTextStyle = {
  fontSize: {xs: '18px', sm: '32px'},
};

const smallerTextStyle = {
  fontSize: {xs: '16px', sm: '18px'},
  width: '100%',
  textAlign: 'center',
}

export default function Home() {

  return (
    <Container maxWidth="lg" sx={containerStyle}>
      <Box component="div" sx={headerContainerStyle}>
        <img src={'../header-image-nettimetsa.png'} alt={""} style={headerImageStyle} />
        <Typography variant='h1' sx={headerTextStyle}><b>Nettimetsä.fi</b><br/><Typography sx={headerSecondTextStyle}><b>Julkinen metsätilojen ja -palveluiden markkinapaikka</b></Typography></Typography>
      </Box>
      <Typography variant="h4" sx={smallHeaderStyle} ><b>Nettimetsä.fi</b></Typography>
      <Typography variant="body2"sx={smallerTextStyle}>
        <b>Nettimetsä.fi</b> on julkinen metsätilojen ja palveluiden markkinapaikka. 
        Nettimetsä perustettiin, jotta metsänomistajat voisivat löytää yhdestä paikasta myytävät metsätilat sekä paikalliset palveluntarjoajat. 
        Nettimetsä.fi on vuonna 2023 perustetun Jesa Group Oy:n omistama ja ylläpitämä nettisivu. 
      </Typography>
      <Typography variant="h4" sx={smallHeaderStyle} ><b>Metsätilojen, yhteismetsäosuuksien ja tonttien myynti</b></Typography>
      <Typography variant="body2"sx={smallerTextStyle}>
        Myynti-ilmoitusten luonti Nettimetsään on täysin ilmaista eikä se sido sinua mihinkään. 
        Missionamme on tehostaa metsätilamarkkinoita tarjoamalla kaikille ilmaisen metsätilojen myyntiin suunnitellun alustan. 
      </Typography>
      <Typography variant="h4" sx={smallHeaderStyle} ><b>Paikalliset palveluntarjoajat</b></Typography>
      <Typography variant="body2"sx={smallerTextStyle}>
        Nettimetsä.fi palvelusta löydät paikalliset palveluntarjoajat kunnittain helposti ilman välikäsiä. 
        Nettimetsän kautta metsänomistaja voi ottaa suoraan yhteyttä yrittäjään. 
      </Typography>
      <Typography variant="h4" sx={smallHeaderStyle} ><b>Palveluiden ilmoittaminen</b></Typography>
      <Typography variant="body2"sx={smallerTextStyle}>
        Voit ilmoittaa palvelusi olemalla yhteydessä sähköpostiin <a href='mailto:yllapito@nettimetsa.fi'>yllapito@nettimetsa.fi</a> tai numeroon 0400504124. 
      </Typography>
      <Typography variant="h4" sx={smallHeaderStyle} ><b>Metsätilan myyjän opas</b></Typography>
      <Typography variant="body2" sx={smallerTextStyle}>Syötä sähköpostisi ja puhelinnumerosi, niin lähetämme sinulle ilmaisen metsätilan myyjän oppaan.</Typography>
      <ContactSubmit />
    </Container>
  );
}